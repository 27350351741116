<template>

 <v-app id="inspire">
    <nav>
    <v-toolbar>
     <v-toolbar-title class="headline text-uppercase">
     </v-toolbar-title>

                  <v-spacer></v-spacer>

            <img src="/img/logo.png" style="width: 164px;">

     <v-spacer></v-spacer>
    <div class="text-right mr-2">
              <v-btn outlined color="primary" @click="$router.push('/register')">Register</v-btn>
      </div>
   </v-toolbar>
 </nav>
    <v-main>
   

      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                id="LoginToolbar"
              >
                <v-toolbar-title style="color: white;">Login</v-toolbar-title>
                <div class="flex-grow-1"></div>

              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation>
                    <v-text-field
                      ref="email"
                      v-model="email"
                      :rules="[() => !!email || 'This field is required']"
                      prepend-icon="mdi-account"
                      label="Login"
                      placeholder="Please enter your email"
                      required
                    />
                    <v-text-field
                      ref="password"
                      v-model="password"
                      :rules="[() => !!password || 'This field is required']"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      prepend-icon="mdi-lock"
                      label="Password"
                      placeholder="*********"
                      counter
                      required
                      @keydown.enter="login"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="forgotPasswordDialog = true">Forgot Password</v-btn>
                <v-btn color="primary" @click="login">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
            <v-dialog
      v-model="forgotPasswordDialog"
      persistent
      max-width="600px"
    >
 
      <v-card>
        <v-card-title>
          <span class="headline">Forgot Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
            <v-text-field
              v-model="forgot_email"
              label="Email"
              outlined
            
          ></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="forgotPasswordDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="forgotPassword()"
          >
            Reset Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-snackbar
        v-model="snackbar"
        :color="color"
        :top='true'
      >
        {{ snackbarMessage }}
        <v-btn
          dark

          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>

</style>
<script>


export default {
  data: function () {
    return {
      email: '',
      password: '',
      snackbarMessage: 'Incorrect login info',
      snackbar: false,
      color: 'general',
      valid: '',
      showPassword: false,
      forgotPasswordDialog: false,
      forgot_email: ""
    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    login: function () {
      let email = this.email
      let password = this.password
        if (this.$refs.form.validate()) {
      this.$store.dispatch('login', { email, password })
        .then( response => {
          console.log(response)
          this.$router.go('/dashboard')
        }

        )
        .catch(err => {
          console.log(err)
        this.snackbar= true
        })
      }else {
        this.snackbarMessage = "Kindly fill in both fields"
        this.snackbar= true
      }
    },
     forgotPassword: function () {
        this.$store.dispatch('forgotPassword', { email: this.forgot_email })
        .then( response => {
          
           this.snackbarMessage = response.data
           this.snackbar= true
        }

        )
        .catch(err => {
           this.snackbarMessage = err.response.data
           this.snackbar= true
        })
    }
  },
  metaInfo () {
    return {
      title: 'Cubixd | Login'
    }
  }
}
</script>
